<template>
  <window-content v-if="!loading && !errors && !success">
    <e-row class="w-lote">
      <e-col style="max-width: 600px">

        <e-row mr class="m-t">
          <e-col style="max-width: 200px">
            <erp-s-field
                view="lr"
                label="ID:"
                label-width="130px"
            >
              <erp-input readonly v-model="pagamento.id" />
            </erp-s-field>
          </e-col>

          <e-col>
            <erp-s-field
                view="lr"
                label="Valor:"
            >
              <erp-input readonly v-model="pagamento.valor" />
            </erp-s-field>
          </e-col>

          <e-col>
            <erp-s-field
                view="lr"
                label="Data:"
            >
              <erp-input readonly v-model="pagamento.data" />
            </erp-s-field>
          </e-col>
        </e-row>

        <e-row v-if="pagamento.pagamentoPrincipal" mr class="mt m-b-n">
          <e-col style="padding-left: 130px; font-size: 11px; font-weight: bold; color: #3b5bfd">
            Este pagamento faz parte de um pagamento principal, que foi fragmentado de acordo a divisão dos valores pendentes no momento do registro do mesmo.
          </e-col>
        </e-row>

        <e-row v-if="pagamento.pagamentoPrincipal" mr class="m-t">
          <e-col style="max-width: 200px">
            <erp-s-field
                view="lr"
                label="ID:"
                label-width="130px"
            >
              <erp-input readonly v-model="pagamento.pagamentoPrincipal.id" />
            </erp-s-field>
          </e-col>

          <e-col>
            <erp-s-field
                view="lr"
                label="Valor:"
            >
              <erp-input readonly v-model="pagamento.pagamentoPrincipal.valor" />
            </erp-s-field>
          </e-col>
        </e-row>

        <e-row mr>
          <e-col style="min-width: 250px">
            <erp-s-field
                view="lr"
                label="Tipo Pagante:"
                label-width="130px"
            >
              <erp-select
                  placeholder="Selecione"
                  disable
                  :options="[{label: 'Próprio', value: 1},{label: 'Terceiros', value: 2}]"
                  v-model="pagamento.tipoPagante"
              ></erp-select>
            </erp-s-field>
          </e-col>
        </e-row>

        <e-row mr>
          <e-col>
            <erp-s-field
                view="lr"
                label="Banco:"
                label-width="130px"
            >
              <erp-input readonly :value="pagamento.banco ? `${pagamento.banco.codigoBanco} - ${pagamento.banco.nome}` : '-'" />
            </erp-s-field>
          </e-col>

          <e-col>
            <erp-s-field
                view="lr"
                label="Método:"
            >
              <erp-input readonly :value="pagamento.metodo ? `${pagamento.metodo.nome}` : '-'" />
            </erp-s-field>
          </e-col>
        </e-row>

        <e-row mr>
          <e-col>
            <erp-s-field
                view="lr"
                label="Pagante:"
                label-width="130px"
            >
              <erp-input readonly v-model="pagamento.nomePagamento" />
            </erp-s-field>
          </e-col>

          <e-col>
            <erp-s-field
                view="lr"
                label="Documento:"
            >
              <erp-input readonly v-model="pagamento.documentoPagante" /> <!-- TODO: Formatar CPF ou CNPJ se existir -->
            </erp-s-field>
          </e-col>
        </e-row>

        <e-row v-if="pagamento.arremate" mr>
          <e-col>
            <erp-s-field
                view="lr"
                label="Arremate:"
                label-width="130px"
            >
              <helper-input-btn>
                <erp-input slot="input" readonly :value="`LOTE ${pagamento.arremate.lote.numero} - ${pagamento.arremate.lote.descricao}`" />
                <a @click="windowLote(pagamento.arremate.lote)" class="m-l-xs text-black font-10 text-nowrap no-select"><i class="erp-icon search min"></i> </a>
              </helper-input-btn>
            </erp-s-field>
          </e-col>
        </e-row>

        <e-row mr>
          <e-col style="max-width: 300px">
            <erp-s-field
                view="lr"
                label="Registro:"
                label-width="130px"
            >
              <erp-input readonly v-model="pagamento.createdAt.date" />
            </erp-s-field>
          </e-col>

          <e-col>
            <erp-s-field
                view="lr"
                label="Operador:"
            >
              <erp-input readonly v-model="pagamento.createdByUser" :suffix="`ID: ${pagamento.createdBy}`"/>
            </erp-s-field>
          </e-col>
        </e-row>

        <e-row mr>
          <e-col>
            <erp-s-field
                view="lr"
                label="Status:"
                label-width="130px"
            >
              <pagamento-status :pagamento="pagamento" />
            </erp-s-field>
          </e-col>
        </e-row>

        <e-row v-if="pagamento.dateModified" mr>
          <e-col style="max-width: 300px">
            <erp-s-field
                view="lr"
                label="Última Modificação:"
                label-width="130px"
            >
              <erp-input readonly :value="pagamento.dateModified ? pagamento.dateModified.date : 'Nunca'" />
            </erp-s-field>
          </e-col>

          <e-col>
            <erp-s-field
                view="lr"
                label="Operador:"
            >
              <erp-input readonly v-model="pagamento.modifiedByUser" :suffix="pagamento.modifiedBy ? `ID: ${pagamento.modifiedBy}` : ''" />
            </erp-s-field>
          </e-col>
        </e-row>

      </e-col>
    </e-row>
    <window-footer class="flex content-between">
      <!--<div class="flex items-center justify-center font-11">
        <div class="m-l-xs">
          Ao salvar o leilão ele ficará com status Rascunho. Após validar as informações, altere o
          status para Em breve.
        </div>
      </div>-->
      <div class="inline-block window-actions content-between col-grow">
        <u-btn v-if="Number(pagamento.status) <= 1" label="Cancelar Pagamento" color="negative" icon="times" icon-type="fa" icon-color="negative" icon-style="light" flat no-caps
               @click="cancel"
               v-shortkey="['f2']" @shortkey.native="save" class="pull-left" />
        <u-btn label="Fechar" icon="times" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />

      </div>
    </window-footer>
  </window-content>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
    </div>
    <h5 class="m-t">Pagamento modificado com sucesso!</h5>
    <div class="text-center m-t">
      <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
    </div>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
import {
  WindowContent,
  // WindowHeader,
  // WindowBody,
  WindowFooter,
  // ErpTabItem,
  // ErpTabs,
  ErpSField,
  ErpInput,
  // HelperInputBtn,
  // ErpBox,
  // ErpLabel,
  ErpSelect,
  // ErpCheckbox,
  // ErpBox,
  HelperInputBtn
} from 'uloc-vue-plugin-erp'
import WindowLoading from '../../../layout/window/Loading'
import TimerCloseWindow from '../../../layout/window/TimerClose'
import WindowSuccess from '../../../layout/window/Success'
import {VMoney} from 'v-money'
import {REAL_BRL, convertRealToMoney} from '../../../../utils/money'
import {atualizaPagamentoLote, findPagamentoLote as find, cancelarPagamentoLote} from '../../../../domain/leiloes/services/index'
// import DefaultAsyncSelect from '../../../../reuse/input/DefaultAsyncSelect'
import ERow from '../../../layout/components/Row'
import ECol from '../../../layout/components/Col'
import {datePtToEn} from '../../../../utils/date'
import formEntitySchema from '../../../../domain/formentity/schema'
import windowLote from '../../windows/lote'
import PagamentoStatus from '../include/PagamentoStatus'

let mockPagamento = {
  id: null,
  tipoPagante: null,
  data: null,
  nomePagamento: null,
  documentoPagante: null,
  observacoes: null,
  metodo: null,
  banco: null,
  valor: null,
  arremate: null,
  ...formEntitySchema
}

export default {
  name: 'PagamentoWindow',
  props: ['id'],
  directives: {money: VMoney},
  data () {
    return {
      money: REAL_BRL,
      loading: true,
      errors: null,
      success: false,
      status: null,
      pagamento: JSON.parse(JSON.stringify(mockPagamento)),
      mock: {},
      hasAlteracao: false,
      cache: {}
    }
  },
  mounted () {
    this.loading = true
    this.id && find(this.id)
      .then(response => {
        let data = response.data

        data.valor = 'R$ ' + this.$options.filters.moeda(data.valor || 0)
        data.data = this.$options.filters.formatDate(data.data.date, 'dd/MM/yyyy')
        if (data.pagamentoPrincipal) {
          data.pagamentoPrincipal.valor = 'R$ ' + this.$options.filters.moeda(data.pagamentoPrincipal.valor || 0)
          data.pagamentoPrincipal.data = this.$options.filters.formatDate(data.pagamentoPrincipal.data.date, 'dd/MM/yyyy')
        }

        if (data.arrematante && data.arrematante.id) {
          this.cache.arrematanteSelected = data.arrematante
          data.arrematante = data.arrematante.id
        }

        this.pagamento = data
        this.$nextTick(() => {
          this.loading = false
        })
      })
      .catch((error) => {
        this.$uloc.dialog({
          title: 'Falha ao carregar!',
          color: 'negative',
          message: `Não conseguimos carregar o pagamento, entre em contato com o administrador.`
        })
        this.loading = false
        this.errors = error.data
        console.error(error, error.data)
      })
  },
  computed: {},
  watch: {},
  methods: {
    cancel () {
      this.$uloc.actionSheet({
        title: 'Cancelar Pagamento',
        grid: false,
        actions: [
          {
            label: 'Estornar',
            icon: 'money-check-alt',
            iconType: 'fa',
            handler: () => {
              // this.$uloc.notify({color: 'grey', message: ''})
              this.$uloc.dialog({
                title: 'Você está prestes a estornar um pagamento',
                message: `Ao estornar, o pagamento será cancelado e também será feito um lancamento no finananceiro de devolução do valor. Tem certeza que deseja estornar?`,
                noCaps: true,
                ok: `Confirmar estorno`,
                cancel: 'Não'
              })
                .then(() => {
                  // this.$emit('updateStatus', newStatus)
                  cancelarPagamentoLote(this.pagamento.id, true)
                    .then(response => {
                      console.log(response.data)
                      this.$uloc.notify({color: 'grey', message: 'Pagamento estornado com sucesso'})
                      this.pagamento.status = response.data.status
                    })
                    .catch(error => {
                      this.alertApiError(error)
                    })
                })
                .catch(() => {

                })
            }
          },
          {
            label: 'Apenas Cancelar',
            icon: 'ban',
            iconType: 'fa',
            handler: () => {
              // this.$uloc.notify({color: 'grey', message: ''})
              this.$uloc.dialog({
                title: 'Cancelar pagamento',
                message: `Tem certeza que deseja cancelar? Caso queira efetuar a devolucão do valor, use o estorno.`,
                noCaps: true,
                ok: `Confirmar cancelamento`,
                cancel: 'Não'
              })
                .then(() => {
                  // this.$emit('updateStatus', newStatus)
                  cancelarPagamentoLote(this.pagamento.id, false)
                    .then(response => {
                      console.log(response.data)
                      this.$uloc.notify({color: 'grey', message: 'Pagamento cancelado com sucesso'})
                      this.pagamento.status = response.data.status
                    })
                    .catch(error => {
                      this.alertApiError(error)
                    })
                })
                .catch(() => {

                })
            }
          },
          {}, // separator
          {
            label: 'Fechar',
            icon: 'times',
            iconType: 'fa',
            color: 'negative',
            handler: () => {
              // this.$uloc.notify('Operação cancelada')
            }
          }
        ]
      })
        .catch(() => {
          // dimiss...
        })
    },
    save () {
      console.log('Save')
      this.loading = true
      let data = JSON.parse(JSON.stringify(this.pagamento))

      data.valor = convertRealToMoney(data.valor)
      data.data = datePtToEn(data.data)

      // data.extra = JSON.stringify(data.extra)

      this.status = 'Processando modificação no pagamento'
      atualizaPagamentoLote(this.pagamento.id, data)
        .then(response => {
          const cbSuccess = () => {
            this.success = true
            this.$nextTick(() => {
              // this.$refs.timer.start()
              // this.load()
              window.setTimeout(() => {
                this.loading = false
                this.success = false
              }, 3000)
              this.$uloc.window.emit(this.$root.wid, 'update', this.props)
            })
          }
          cbSuccess()
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          this.alertApiError(error)
        })
    },
    windowLote: windowLote
  },
  components: {
    PagamentoStatus,
    ECol,
    ERow,
    WindowSuccess,
    TimerCloseWindow,
    WindowLoading,
    WindowFooter,
    // WindowBody,
    // WindowHeader,
    WindowContent,
    // ErpTabs,
    // ErpTabItem,
    ErpSField,
    ErpInput,
    // HelperInputBtn,
    // ErpBox,
    // ErpLabel,
    // UEditor,
    // URadio
    ErpSelect,
    // ErpCheckbox,
    // ErpBox,
    HelperInputBtn
  }
}
</script>
